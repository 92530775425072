var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderGoods" },
    _vm._l(_vm.goodsList, function (item, index) {
      return _c("div", { key: index }, [
        _vm.showType
          ? _c("div", { staticClass: "goods-shop" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.getSourceTypes(item.source_type)) +
                  "\n\t\t"
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "order-goods" }, [
          item
            ? _c("div", { staticClass: "goods-img" }, [
                item.small_pic
                  ? _c("img", {
                      attrs: {
                        src: _vm._f("setHttps")(item.small_pic),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: _vm._f("setHttps")(item.base_pic),
                        alt: "",
                      },
                    }),
                item.group_id && item.group_id > 0
                  ? _c("div", { staticClass: "group-tip" }, [
                      _c("span", [_vm._v("3人成团")]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          item
            ? _c("div", { staticClass: "goods-info df fdc jc-sb" }, [
                _c("div", [
                  _c("p", { staticClass: "goods-name" }, [
                    _vm._v(_vm._s(item.item_name)),
                  ]),
                  _c("p", { staticClass: "goods-intro" }, [
                    _vm._v(_vm._s(item.item_desc)),
                  ]),
                ]),
                item.property
                  ? _c("div", { staticClass: "sku-box" }, [
                      _c("div", { staticClass: "goods-sku" }, [
                        _vm._v(_vm._s(item.property)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.businessType != 4
                  ? _c("div", { staticClass: "goods-price" }, [
                      _vm.businessType == 2
                        ? _c("div", { staticClass: "price" }, [
                            _vm.price_status == 1
                              ? _c(
                                  "span",
                                  { staticClass: "real-price fs-weight" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "fs-12 fs-weight" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              item.display_type === 2 ||
                                                (_vm.tripartite_type ===
                                                  "VUE_APP_ZJG" &&
                                                  item.integral_usage > 0)
                                                ? `${(
                                                    item.price -
                                                    _vm.cashPart(
                                                      item.integral_usage
                                                    )
                                                  ).toFixed(2)}元+${
                                                    item.integral_usage
                                                  }积分`
                                                : _vm.priceShow(
                                                    item.display_type,
                                                    item.price,
                                                    item.max_integral_percentage,
                                                    Math.floor(
                                                      Number(
                                                        item.reduce_integral
                                                      ) / item.number
                                                    )
                                                  )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.mall_price_status == 1 &&
                            item.display_type !== 2
                              ? _c("s", { staticClass: "fs-12" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.priceShow(
                                          item.display_type,
                                          item.mall_price
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _c("div", { staticClass: "price" }, [
                            _vm.price_status == 1
                              ? _c(
                                  "span",
                                  { staticClass: "real-price fs-weight" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fs-12 fs-weight",
                                        staticStyle: {
                                          "margin-right": "0.05rem",
                                        },
                                      },
                                      [_vm._v("¥")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "fs-16 fs-weight" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              Math.ceil(
                                                (item.price -
                                                  (item.reduce_integral /
                                                    item.number || 0)) *
                                                  100
                                              ) /
                                                100 >=
                                                0
                                                ? Math.ceil(
                                                    (item.price -
                                                      (item.reduce_integral /
                                                        item.number || 0)) *
                                                      100
                                                  ) / 100
                                                : 0
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.mall_price_status == 1
                              ? _c("s", { staticClass: "fs-12" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t¥" +
                                      _vm._s(item.mall_price) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                      _c("div", { staticClass: "num fs-12" }, [
                        _vm._v("x" + _vm._s(item.number)),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }