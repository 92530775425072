<template>
	<div class="orderGoods">
		<div v-for="(item, index) in goodsList" :key="index">
			<div v-if="showType" class="goods-shop">
				{{ getSourceTypes(item.source_type) }}
			</div>
			<div class="order-goods">
				<div v-if="item" class="goods-img">
					<img v-if="item.small_pic" :src="item.small_pic | setHttps" alt />
					<img v-else :src="item.base_pic | setHttps" alt />
					<div v-if="item.group_id && item.group_id > 0" class="group-tip">
						<span>3人成团</span>
					</div>
				</div>
				<div v-if="item" class="goods-info df fdc jc-sb">
					<div>
						<p class="goods-name">{{ item.item_name }}</p>
						<p class="goods-intro">{{ item.item_desc }}</p>
					</div>
					<div v-if="item.property" class="sku-box">
						<div class="goods-sku">{{ item.property }}</div>
					</div>
					<div v-if="businessType != 4" class="goods-price">
						<div  v-if="businessType == 2" class="price">
							<span  v-if="price_status == 1" class="real-price fs-weight">
								<span class="fs-12 fs-weight">
									{{
										item.display_type === 2 || (tripartite_type === 'VUE_APP_ZJG' && item.integral_usage > 0) ?
										`${(item.price - cashPart(item.integral_usage)).toFixed(2)}元+${item.integral_usage}积分` :
										priceShow(item.display_type, item.price, item.max_integral_percentage, Math.floor(Number(item.reduce_integral) / item.number))
									}}
								</span>
							</span>
							<s v-if="mall_price_status == 1 && item.display_type !== 2" class="fs-12">
								{{ priceShow(item.display_type ,item.mall_price) }}
							</s>
						</div>
						<div v-else class="price">
							<span v-if="price_status == 1" class="real-price fs-weight">
								<span class="fs-12 fs-weight" style="margin-right: 0.05rem;">¥</span>
								<span class="fs-16 fs-weight">
									{{ 
										Math.ceil((item.price - (item.reduce_integral / item.number || 0)) * 100) / 100 >= 0 ?
										Math.ceil((item.price - (item.reduce_integral / item.number || 0)) * 100) / 100 : 0 
									}}
								</span>
							</span>
							<s v-if="mall_price_status == 1" class="fs-12">
								¥{{ item.mall_price }}
							</s>
						</div>
						<div class="num fs-12">x{{ item.number }}</div>
					</div>
					<!-- <p v-if="businessType === 2 && showWay == 2"
						 style="color: var(--main-color);">
						最多使用{{maxIntegral(item.price, integralRate, item.max_integral)}}积分</p> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { orderMixin } from '@/mixins/orderMixin.js';
import { priceShow, integralPart, cashPart } from '@/utils/goodsUtil';
import { mapState } from 'vuex';

export default {
	name: 'orderGoods',
	mixins: [orderMixin],
	props: {
		goodsList: {
			type: Array,
			default: () => []
		},
		showType: {
			type: Boolean,
			default: false
		},
		show_max: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			showWay: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')).show_way : '',
			integralRate: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')).integral_rate : ''
		};
	},
	computed: {
		...mapState({
			businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
			// showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
			price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
			mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
			// integralRate: state => state.config.integral_rate // 积分比例
			tripartite_type: state => state.tripartite_type // 三方商城类型
		})
	},
	watch: {
		goodsList: {
			handler: function(val) {
				let self = this;
				val.forEach(function(item) {
					let number = item.number,
						price = Number.parseFloat(item.price),
						max_integral = Number.parseFloat(item.max_integral) / 100;
					if (self.businessType == 2 && self.showWay == 1 && max_integral < 1) {
						let max_integral = Number.parseFloat(item.max_integral) / 100;
						self.$set(item, 'max_integral_num', max_integral * number * price * self.integralRate);
					} else {
						self.$set(item, 'max_integral_num', 0);
					}
				});
				this.goodsList = val;
				// console.log(this.goodsList, '234254', this.integralRate);
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		// 积分3.0显示
		priceShow,
		
		// 现金转积分
		integralPart,
		
		// 积分转现金
		cashPart,

		getCash(price, max, num) {
			let theMax = Number.parseFloat(max) / 100;
			let cash = (price * (1 - theMax) * num).toFixed(2);
			if (cash) {
				return `+${cash}元`;
			} else {
				return '';
			}
		}

		//积分商城现金形式展示时显示最多使用积分
		/* maxIntegral (price, rate, max) {
			let integralRatio =  Number.parseFloat(max / 100),
				moneyRatio = 1 - integralRatio,						//需要现金的比例
				theMoney = Math.ceil(price * moneyRatio * 100),
				moneyPath = theMoney / 100;
				
				//最多可使用积分
			return Math.ceil((price - moneyPath) * rate);
		}, */
	},
	mounted() {
		console.log('goodsList:', this.goodsList);
	}
};
</script>
<style scoped lang="less">
@import '~@/common/less/variable.less';
@import '~@/common/less/mixin.less';

.order-goods {
	display: flex;
	align-items: center;
	margin-bottom: 0.2rem;

	.goods-img {
		width: 2.2rem;
		height: 2.2rem;
		position: relative;

		img {
			width: 2.2rem;
			height: 2.2rem;
		}

		.group-tip {
			padding: 3px 6px;
			border-radius: 1000px;
			line-height: 1;
			font-size: 12px;
			color: #fff;
			position: absolute;
			left: 1px;
			top: 0.1rem;
			box-sizing: border-box;
			white-space: nowrap;
			background-color: rgb(254, 85, 46);
			transform: scale(1);
			-webkit-transform-origin: center center;
			transform-origin: center center;

			span {
				font-size: 0.22rem;
				color: #fff;
				line-height: 0.32rem;
				white-space: nowrap;
			}
		}
	}

	.goods-info {
		height: 2.2rem;
		flex: 1;
		padding: 0.24rem 0.1rem 0.2rem 0.2rem;
		box-sizing: border-box;
		background: #fff;

		.goods-name {
			font-size: 0.28rem;
			color: rgba(0, 0, 0, 0.87);
			line-height: 0.4rem;
			.ellipsis(1);
		}

		.goods-intro {
			font-size: 0.24rem;
			color: #999999;
			line-height: 0.34rem;
			.ellipsis(1);
			margin-bottom: 0.12rem;
		}

		.sku-box {
			display: flex;
			margin-bottom: 0.16rem;

			.goods-sku {
				height: 0.36rem;
				line-height: 0.36rem;
				padding: 0 0.12rem;
				box-sizing: border-box;
				border-radius: 0.36rem;
				border: 1px solid var(--main-color);
				color: var(--main-color);
				font-size: 0.22rem;
			}
		}

		.goods-price {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.price {
				.real-price {
					color: @color-dark-grey;
					margin-right: 6px;
				}

				s {
					color: rgba(0, 0, 0, 0.38);
				}
			}

			.num {
				color: @color-dark-grey;
			}
		}
	}

	.fs-weight {
		font-weight: bold;
	}

	.fs-12 {
		font-size: 0.24rem;
	}

	.fs-16 {
		font-size: 0.32rem;
	}
}
</style>
